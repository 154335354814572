import './App.css';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router';
import Layout from './Layout/Layout';
import HomeView from './views/HomeView/HomeView';
import SignInView from './views/SignInView/SignInView';
import LayoutUser from './Layout/LayoutUser';
import AdminTrainingsView from './views/AdminTrainingsView/AdminTrainingsView';
import AdminUsersView from './views/AdminUsersView/AdminUsersView';
import AdminTrainingView from './views/AdminTrainingView/AdminTrainingView';
import AdminTrainingDayView from './views/AdminTrainingDayView/AdminTrainingDayView';
import AdminCreatePlanView from './views/AdminCreatePlanView/AdminCreatePlanView';
import ShowMessage from './components/ShowMessage/ShowMessage';
import NotFoundView from './views/NotFoundView/NotFoundView';
import { Navigate } from 'react-router-dom';
import UserProfileView from './views/UserProfileView/UserProfileView';
import CoachingView from './views/CoachingView/CoachingView';
import CoachingPlanView from './views/CoachingPlanView/CoachingPlanView';
import SignUpView from './views/SignUpView/SignUpView';
import UserView from './views/UserView/UserView';
import ScanningView from './views/ScanningView/ScanningView';
import InstructionView from './views/InstructionView/InstructionView';
import RestorePasswordView from './views/RestorePasswordView/RestorePasswordView';
import CoachingDayView from './views/CoachingDayView/CoachingDayView';
import AdminAllPlansView from './views/AdminAllPlansView/AdminAllPlansView';
import AdminProfileView from './views/AdminProfileView/AdminProfileView';
import AdminNotificationsView from './views/AdminNotificationsView/AdminNotificationsView';
import NotificationsView from './views/NotificationsView/NotificationsView';
import TrainingView from './views/TrainingView/TrainingView';
import LayoutHome from './Layout/LayoutHome';
import CoachesView from './views/CoachesView/CoachesView';
import ProductsView from './views/ProductsView/ProductsView';
import AboutView from './views/AboutView/AboutView';
import 'react-quill/dist/quill.snow.css';
import BillingView from './views/BillingView/BillingView';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import RedirectView from './views/RedirectView/RedirectView';

function App() {
    const stripePromise = loadStripe('pk_test_51PTQWaRw7BHIuVach2Efmr7aS6W0Z53fhBYHp4LBqfN3wZjn5WaO92ShPk1LZanOikwOjhOGxJncMXaEJwpnJGni00MOr1F8TZ');

    return (
        <div className="app">
            <Elements stripe={stripePromise}>

                {/* <Suspense fallback={<Preloader />}> */}
                <Suspense>
                    <Routes>
                        <Route path="/" element={<LayoutHome />}>
                            <Route index element={<HomeView />} />
                            <Route path="/coaches" element={<CoachesView />}/>
                            <Route path="/products" element={<ProductsView />}/>
                            <Route path="/about" element={<AboutView />}/>
                            <Route path="*" element={<Navigate to="/not-found" />} />
                            <Route path="/not-found" element={<NotFoundView isLayoutHome={true}/>}/>
                        </Route>
                        <Route path="/login" element={<SignInView />}/>
                        <Route path="/register" element={<SignUpView />}/>
                        <Route path="/restore" element={<RestorePasswordView />}/>
                        <Route path="/training/:idTraining" element={<TrainingView />}/>
                        <Route path="*" element={<Navigate to="/not-found" />} />
                        <Route path="/not-found" element={<NotFoundView />} />
                        {/* <Route path="/offer" element={<OfferView />} /> */}
                        <Route path="/redirect" element={<RedirectView />} />

                        <Route path="user/:userId" element={<Layout />}>
                            <Route index element={<UserView />}/>
                            <Route path=":coachingId" element={<CoachingView />} />
                            <Route path=":coachingId/:coachingDayId" element={<CoachingDayView />} />
                            <Route path=":coachingId/:coachingDayId/:coachingPlanId" element={<CoachingPlanView />} />
                            <Route path=":coachingId/:coachingDayId/:coachingPlanId/instractions" element={<InstructionView />} />
                            <Route path="profile" element={<UserProfileView />} />
                            <Route path="scanning" element={<ScanningView />} />
                            <Route path="notifications" element={<NotificationsView />}/>
                            <Route path="*" element={<Navigate to="not-found" />} />
                            <Route path="not-found" element={<NotFoundView />}/>
                        </Route>

                        <Route path="/auth/:coachId" element={<LayoutUser />} >
                            <Route index element={<AdminTrainingsView />} />
                            <Route path="trainings" element={<AdminTrainingsView />} />
                            <Route path="trainings/create" element={<AdminTrainingView />} />
                            <Route path="trainings/:trainingId" element={<AdminTrainingView />} />
                            <Route path="trainings/create/:dayId" element={<AdminTrainingDayView />} />
                            <Route path="trainings/:trainingId/:dayId" element={<AdminTrainingDayView />} />
                            <Route path="plans" element={<AdminAllPlansView />} />
                            <Route path="plans/create" element={<AdminCreatePlanView />} />
                            <Route path="plans/:planId" element={<AdminCreatePlanView />} />
                            <Route path="users" element={<AdminUsersView />}/>
                            <Route path="profile" element={<AdminProfileView />}/>
                            <Route path="billing" element={<BillingView />}/>
                            <Route path="notifications" element={<AdminNotificationsView />}/>
                            <Route path="*" element={<Navigate to="not-found" />} />
                            <Route path="not-found" element={<NotFoundView isLayoutUser={true}/>}/>
                        </Route>
                    </Routes>
                    <ShowMessage />
                </Suspense>
            </Elements>
        </div>
  );
}

export default App;
