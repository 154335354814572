import { format, formatDistanceToNow } from "date-fns";
import { countryPhoneLengths, dateFnsLocale } from '../helpers/Config';
import axios from "axios";
import Compressor from "compressorjs";
import { setShowMessageObj } from "../store/homeSlice";
import { translations } from "../localization";

export const validNumber = (value, setValue) => {
    if  (/^\d+$/.test(value)) {
        setValue(value)
        } else {
        setValue('')
    }
}

export const validEmail = (value) => {
    return  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value);
}

export const validatePassword = (str) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(str);
};

export const handleValidURL = (str) => {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)?\.[a-zA-Z]{2,6}(\/[^\s]*)?$/;
    return regex.test(str);
}

export const handleFormatDateWeek = (createAt, locale='en') => {
    return formatDistanceToNow(new Date(createAt), { addSuffix: true, locale: dateFnsLocale[locale] })
}

export const handleHoursAndMinutes = (date) => {
    const dateObject = new Date(date);
    return format(dateObject, 'HH:mm');
}

export const validatePhone = (value, country) => {
    const maxLength = countryPhoneLengths[country];
    if (maxLength) {
        return value.length < maxLength ? false : true;
    } else {
        return true;
    }
};

export const handleUpdatedNotifications = (token, data, fun) => {
    fetchRequest('PUT', `/notifications/updated?token=${token}`, data)
        .then(res => {
            if (res?.success) {
                fun()
            } 
        })
}

export const handleDeleteAssigned = (token, data, id, fun) => {
    fetchRequest('DELETE', `/assigned/${id}?token=${token}`, data)
        .then(res => {
            fun(res)
        })
}

export const handleGetAllExercises = (token, newPage=null, page=0, startSearchValue=null, searchValueSearchParams=null, is_all=false, fun) => {
    fetchGetData(`/exercises/all?is_all=${is_all}&token=${token}&page=${newPage !== null ? newPage : page}&search=${startSearchValue !== null ? startSearchValue : searchValueSearchParams?.length ? searchValueSearchParams : ''}`)
        .then(res => {
            fun(res, newPage)
        })
}

export const handleGetAllTrainings = (token, newPage=null, page=0, startSearchValue=null, searchValueSearchParams=null, is_all=false, fun) => {
    fetchGetData(`/trainings/all?is_all=${is_all}&token=${token}&page=${newPage !== null ? newPage : page}&search=${startSearchValue !== null ? startSearchValue : searchValueSearchParams?.length ? searchValueSearchParams : ''}`)
        .then(res => {
            fun(res, newPage)
        })
}

export const handleSendUpdateEmail = (token, data, fun) => {
    fetchRequest('POST', `/auth/update-email?token=${token}`, data)
        .then(res => {
            fun(res)
        }) 
}

export const handleCheckNewMessages = (token, fun) => {
fetchGetData(`/notifications/unread?token=${token}`)
    .then(res => {
        if (res?.success) {
            if (res?.data) {
                fun(true)
            } else {
                fun(false)
            }
        }
    })
}

export const handleGetInCome = (token, fun) => {
    fetchGetData(`/assigned/income?token=${token}`)
        .then(res => {
            if (res?.success && res?.data) {
            fun(res.data)
            }
        })
}

export const handleSaveMedia = async (token, file) => {
    let formData = new FormData();
    formData.append('file', file);
    return await fetch(`${process.env.REACT_APP_BASE_URL}/files/upload?token=${token}`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(res => {
            if (res?.success && res.data) {
                return res.data
            } else {
                return ''
            }
        })
}

export const fetchGetData = async (url) => {
    try {
        let data = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`);
        return data.data
    } catch (error) {
        console.error('Error:', error);
        return null;
    } 
}

export const fetchRequest = async ( method, url, data, catchFunc=null, finallyFunc=null) => {
    try {
        const response = await axios({
            url: `${process.env.REACT_APP_BASE_URL}${url}`,
            method: method,
            data: data,
        });
        return response?.data;
    } catch (error) {
        console.error('Error:', error);
        if (catchFunc) {
            catchFunc(error);
        }
        return error?.response?.data;
    } finally {
        if (finallyFunc) {
            finallyFunc();
        }
    }
}

export const handleBytesToMegabytes = (bytes) => {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;
    return megabytes.toFixed(2);
}

export const handleFormattingDate = (time, isNotHours=false) => {
    if (isNotHours) {
        let res = <span>
            {`${(new Date(time).getDate() < 10 ? '0' : '') + new Date(time).getDate()}.${((new Date(time).getMonth() + 1) < 10 ? '0' : '') + (new Date(time).getMonth() + 1)}.${new Date(time).getFullYear()}`}
        </span>
        return res
    } else {
        let res = <span>
            {`${(new Date(time).getDate() < 10 ? '0' : '') + new Date(time).getDate()}.${((new Date(time).getMonth() + 1) < 10 ? '0' : '') + (new Date(time).getMonth() + 1)}.${new Date(time).getFullYear()}, ${new Date(time).getHours()}:${(new Date(time).getMinutes() < 10 ? '0' : '') + new Date(time).getMinutes()}`}
        </span>
        return res
    }
}

export const handleCheckApp = (iosScheme, androidPackage, androidUrl, fallbackUrl) => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const openApp = (scheme, androidIntent) => {
    const start = Date.now();
    const ifr = document.createElement('iframe');
    ifr.style.display = 'none';
    ifr.src = scheme;

    const handleBlur = () => {
        clearTimeout(timer);
        window.removeEventListener('blur', handleBlur);
    };

    const handleVisibilityChange = () => {
        if (document.hidden) {
            clearTimeout(timer);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    };

    window.addEventListener('blur', handleBlur);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    document.body.appendChild(ifr);

    const timer = setTimeout(() => {
        document.body.removeChild(ifr);
        window.removeEventListener('blur', handleBlur);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        if (Date.now() - start < 2000) {
            window.location = fallbackUrl;
        }
    }, 1500);
    };

    if (isiOS) {
        openApp(iosScheme);
    } else if (isAndroid) {
        openApp(androidUrl);
    } else {
        window.location = fallbackUrl;
    }
};

export const handleGeoLocation = () => {
    const userLanguage = navigator.language;
    if (userLanguage && userLanguage.includes('uk')) {
        return 'ua';
    } else {
        return 'en';
    }
};

export const handleCheckNewEmailTime = (date) => {
    const now = new Date();
    const pastDate = new Date(date);
    const hoursDifference = Math.abs(now - pastDate) / 36e5; 
    return hoursDifference > 12;
};

export const handleDeleteToken = (isApp=false, user) => {
    if (isApp) {
        if (user?.is_coach) {
            localStorage.removeItem('token-user')
        } else {
            localStorage.removeItem('token-admin')
        }
    }
}

export const handleFocusAppInput = (boolean=false, fun=null, is_app=false) => {
    if (fun) {
        if (boolean) {
            fun(is_app ? true : false)
        } else {
            fun(false)
        }
    }
}

export const handleUploadImgCompressor = (file = null) => {
    return new Promise((resolve, reject) => {
        if (file && file[0]?.name?.length) {
            new Compressor(file[0], {
                quality: 0.2,
                success: (result) => {
                    resolve(result);
                },
                error(err) {
                    console.error(err.message);
                    reject(null);
                },
            });
        } else {
            resolve(null);
        }
    });
};

export const handleStartLambda = () => {
    fetchGetData('/')
        .then(res => {
            if (res?.success) {
            }
    })
}

export const handleExit = (isApp='', isAdmin=false) => {
    if (isAdmin) {
        localStorage.removeItem('token-admin')
    } else {
        localStorage.removeItem('token-user')
    }
    if (isApp) {
        window.location.assign('/login?is_app=true')
    } else {
        window.location.assign('/login')
    }
}

export const handleUpdateEmail = (tokenSearchParams, dispatch, translations, language) => {
    fetchGetData(`/auth/check-email?token=${tokenSearchParams}`)
        .then(res => {
            if (!res?.success) {
                dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['sorryWrong'][language]}));
            }
            if (res?.success && res.data?.token?.length) {
                if (res.is_coach) {
                    localStorage.setItem("token-admin", res.data.token)
                } else {
                    localStorage.setItem("token-user", res.data.token)
                }
            }
        })
}

export const handleUpdatePassword = (tokenSearchParams, dispatch, language) => {
    fetchGetData(`/auth/check-password?token=${tokenSearchParams}`)
        .then(res => {
            if (!res?.success) {
                dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['sorryWrong'][language]}));
            }
        })
}

export const handleGoStripe = (coach, token, is_app, dispatch, language) => {
    let refreshUrl = `https://www.pro-coach.fit/redirect?url=${encodeURIComponent('https://www.pro-coach.fit/auth/' + coach._id + '/billing')}`;
    let returnUrl = `https://www.pro-coach.fit/redirect?stripe_activated=true&token=${token}&coach_id=${coach._id}&url=${encodeURIComponent('https://www.pro-coach.fit/auth/' + coach._id + '/billing?stripe_activated=true&token=' + token + '&coach_id=' + coach._id)}`;

    if (is_app) {
        refreshUrl = 'https://www.pro-coach.fit/auth/' + coach._id + '/billing';
        returnUrl = 'https://www.pro-coach.fit/auth/' + coach._id + '/billing?stripe_activated=true&token=' + token + '&coach_id=' + coach._id;
    }

    let data = {
        accountId: coach.stripe_id,
        refreshUrl: refreshUrl,
        returnUrl: returnUrl,
    };

    fetchRequest('POST', `/stripe/create-account-link?token=${token}`, data)
        .then(res => {
            if (res?.success && res.data?.url?.length) {
                window.open(res.data?.url, '_blank');
            } else {
                dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}));
            }
        });                                                                 
};
export const formatTime = (timeInSeconds, language) => {
    if (timeInSeconds / 60 < 1) {
        return `${timeInSeconds} ${translations['minutes'][language]}`;
    }
    return `${Math.floor(timeInSeconds / 60)}:${(timeInSeconds % 60).toString().padStart(2, '0')} ${translations['hour'][language]}`;
};