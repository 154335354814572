import './AdminFooter.css';
import { memo, useMemo } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dumbbell from '../../assets/dumbbell.svg';
import profileImg from '../../assets/profileImg.svg';
import usersImg from '../../assets/usersImg.svg';
import { translations } from '../../localization';

function AdminFooter() {
    const language = useSelector(state => state.userSlice.language);
    const location = useLocation();
    const {coachId} = useParams()

    const handleIsActive = useMemo(() => {
        return (path) => {
            if (location.pathname.startsWith(`/auth/${coachId}/users`) && `/auth/${coachId}/users` === path) {
                return true;
            }
            if (location.pathname.startsWith(`/auth/${coachId}/profile`) && `/auth/${coachId}/profile` === path) {
                return true;
            }
            if ((location.pathname.startsWith(`/auth/${coachId}/trainings`) || location.pathname.startsWith(`/auth/${coachId}/plans`)) && `/auth/${coachId}/trainings` === path) {
                return true;
            }
            return false
        }
    }, [location.pathname]);

    return (
        <div className={`admin-footer ${location.pathname.includes('notifications') ? 'admin-footer--notifications' : ''}`}>
            <NavLink 
                className={`admin-footer__btn ${handleIsActive(`/auth/${coachId}/users`) ? 'admin-footer__btn--active' : ''}`} 
                to={`/auth/${coachId}/users`}
            >
                <img 
                    className={`admin-footer__btn-img ${handleIsActive(`/auth/${coachId}/users`) ? 'admin-footer__btn-img--active' : ''}`} 
                    src={usersImg} 
                    alt='img' 
                />
                <div className={`admin-footer__btn-text ${handleIsActive(`/auth/${coachId}/users`) ? 'admin-footer__btn-text--active' : ''}`}>{translations['users'][language]}</div>
            </NavLink>
            <NavLink 
                className={`admin-footer__btn ${handleIsActive(`/auth/${coachId}/trainings`) ? 'admin-footer__btn--active' : ''}`} 
                to={`/auth/${coachId}/trainings`}
            >
                <img 
                    className={`admin-footer__btn-img ${handleIsActive(`/auth/${coachId}/trainings`) ? 'admin-footer__btn-img--active' : ''}`} 
                    src={dumbbell} 
                    alt='img' 
                />
                <div className={`admin-footer__btn-text ${handleIsActive(`/auth/${coachId}/trainings`) ? 'admin-footer__btn-text--active' : ''}`}>{translations['trainings'][language]}</div>
            </NavLink>
            <NavLink 
                className={`admin-footer__btn ${handleIsActive(`/auth/${coachId}/profile`) ? 'admin-footer__btn--active' : ''}`} 
                to={`/auth/${coachId}/profile`}
            >
                <img 
                    className={`admin-footer__btn-img ${handleIsActive(`/auth/${coachId}/profile`) ? 'admin-footer__btn-img--active' : ''}`} 
                    src={profileImg} 
                    alt='img' 
                />
                <div className={`admin-footer__btn-text ${handleIsActive(`/auth/${coachId}/profile`) ? 'admin-footer__btn-text--active' : ''}`}>{translations['profile'][language]}</div>
            </NavLink>
        </div>
    );
}

export default memo(AdminFooter);