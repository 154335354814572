import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    backToCreateTrening: '',
    windowInnerWidth: window.innerWidth,
    language: 'en',
    exerciseObj: {},
    exercises: [],
    trainingObj: {},
    trainings: [],
    users: [],
    modalNewNotifications: [],
    modalSeenNotifications: [],
    seenNotifications: [],
    newNotifications: [],
    isPreloaderSearch: false,
    isUnreadMessages: false,
    errorExerciseObj: {}, 
    searchValue: '',
    isInputFocus: false,
    isModalFinished: false,
    is_app: false,
    isModalStripeRegistration: false,
    coach: {
        _id: "123456789",
        first_name: 'Oleg',
        last_name: 'Kobrynovych',
    },
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setBackToCreateTrening: (state, action) => {
            state.backToCreateTrening = action.payload;
        },
        setWindowInnerWidth: (state, action) => {
            state.windowInnerWidth = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setCoach: (state, action) => {
            state.coach = action.payload;
        },
        setExerciseObj: (state, action) => {
            state.exerciseObj = action.payload;
        },
        setExercises: (state, action) => {
            state.exercises = action.payload;
        },
        setTrainings: (state, action) => {
            state.trainings = action.payload;
        },
        setTrainingObj: (state, action) => {
            state.trainingObj = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setIsPreloaderSearch: (state, action) => {
            state.isPreloaderSearch = action.payload;
        },
        setModalNewNotifications: (state, action) => {
            state.modalNewNotifications = action.payload;
        },
        setModalSeenNotifications: (state, action) => {
            state.modalSeenNotifications = action.payload;
        },
        setIsUnreadMessages: (state, action) => {
            state.isUnreadMessages = action.payload;
        },
        setErrorExerciseObj: (state, action) => {
            state.errorExerciseObj = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setSeenNotifications: (state, action) => {
            state.seenNotifications = action.payload;
        },
        setNewNotifications: (state, action) => {
            state.newNotifications = action.payload;
        },
        setIsInputFocus: (state, action) => {
            state.isInputFocus = action.payload;
        },
        setIsModalFinished: (state, action) => {
            state.isModalFinished = action.payload;
        },
        setIs_app: (state, action) => {
            state.is_app = action.payload;
        },
        setIsModalStripeRegistration: (state, action) => {
            state.isModalStripeRegistration = action.payload;
        },
    },
})

export const { setIsModalStripeRegistration, setIs_app, setIsModalFinished, setIsInputFocus, setNewNotifications, setSeenNotifications, setSearchValue, setModalSeenNotifications, setModalNewNotifications, setIsUnreadMessages, setErrorExerciseObj, setIsPreloaderSearch, setUsers, setTrainingObj, setTrainings, setExercises, setCoach, setExerciseObj, setLanguage, setWindowInnerWidth, setBackToCreateTrening } = userSlice.actions

export default userSlice.reducer