import './AdminAllPlansView.css';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import copyImgViolet from '../../assets/copyImgViolet.svg';
import editViolet from '../../assets/editViolet.svg';
import deleteImgViolet from '../../assets/deleteImgViolet.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import TextInput from '../../components/TextInput/TextInput';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { useSelector } from 'react-redux';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { setExerciseObj, setExercises, setSearchValue} from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { fetchRequest, formatTime, handleGetAllExercises, handleGetInCome } from '../../helpers/Utils';
import { setShowMessageObj } from '../../store/homeSlice';
import helpIcon from '../../assets/helpIcon.svg';
import InfiniteScroll from 'react-infinite-scroller';
import noPhotos from '../../assets/noPhotos.svg';
import restTimeImg from '../../assets/restTimeImg.svg';
import notTreningImg from '../../assets/notTreningImg.svg';

function AdminAllPlansView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const searchValueSearchParams = JSON.parse(searchParams.get('searchValue'))
    const windowInnerWidth = useSelector(state => state.userSlice.windowInnerWidth);
    const language = useSelector(state => state.userSlice.language);
    const exercises = useSelector(state => state.userSlice.exercises);
    const searchValue = useSelector(state => state.userSlice.searchValue);
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [deletePlan, setDeletePlan] = useState({});
    const [isModalCopy, setIsModalCopy] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [isHasMore, setIsHasMore] = useState(false);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const [objCopy, setObjCopy] = useState(null);
    const [newTreningName, setNewTreningName] = useState('');
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [inCome, setInCome] = useState(0);
    const { coachId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollParentRef = useRef(null)
    let token = localStorage.getItem('token-admin')
    
    useEffect(() => {
        setIsNotFirstRender(true);
        handleGetExercises(0, searchValueSearchParams?.length ? searchValueSearchParams : '')
        handleGetInCome(token, (res) => setInCome(res))
        dispatch(setSearchValue(searchValueSearchParams?.length ? searchValueSearchParams : ''));

        return () => {
            dispatch(setSearchValue(''));
        }
    }, [])

    useEffect(() => {
        if (isNotFirstRender && searchValueSearchParams === searchValue) {
            handleGetExercises(0)
            setPage(0);
        }
    }, [searchValueSearchParams])

    const handleCopt = (obj) => {
        setIsModalCopy(true);
        setObjCopy(obj);
    };

    const handleEdite = (obj) => {
        if (obj._id?.length) {
            dispatch(setExerciseObj(obj))
            navigate(`/auth/${coachId}/plans/${obj._id}`);
        } 
    }

    const handleDelete = (obj) => {
        setIsModalDelete(true)
        setDeletePlan(obj);
    }

    const handleIsCopyPlan = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            let data = {};
            const excludedFields = ['_id', 'create_at'];
            for (let key in objCopy) {
                if (!excludedFields.includes(key)) {
                    data[key] = objCopy[key];
                }
            }
            data['name'] = newTreningName

            fetchRequest('POST', `/exercises?isCopy=true&token=${token}`, data)
                .then(res => {
                    if (res?.success && res.data) {
                        dispatch(setExercises([res.data, ...exercises]))
                    } else {
                        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}))
                    }
                    setIsPreloader(false)
                })

        };
        setIsModalCopy(false);
        setNewTreningName('');
        setObjCopy({});
    }

    const handleIsDeletePlan = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            let data = {
            }
            fetchRequest('DELETE', `/exercises/${deletePlan._id}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'An error occurred'})))
                .then(res => {
                    if (res?.success && res?.data) {
                        dispatch(setExercises(exercises.filter(el => el._id !== deletePlan._id)))
                        dispatch(setShowMessageObj({open: true, status: 'success', message: translations['dataUpdated'][language]}))
                    }
                    setIsPreloader(false)
                })
        };
        setIsModalDelete(false);
        setDeletePlan({});
    }
    
    const handleLoadMore = () => {
        setIsHasMore(false);
        if (isHasMore) {
            handleGetExercises(page + 1);
            setPage(page + 1);
        }
    }

    const handleGetExercises = (newPage=null, startSearchValue=null) => {
        if (newPage === null || newPage === 0) {
            setIsPreloader(true);
        }
        setIsHasMore(false);
        handleGetAllExercises(token, newPage, page, startSearchValue, searchValueSearchParams, false, handleSetExercises);
    }

    const handleSetExercises = (res, newPage) => {
        if (res?.success && res?.data) {
            const newData = res.data?.items || [];
            const updatedData = newPage ? [...exercises, ...newData] : newData;
            dispatch(setExercises(updatedData));
            setIsHasMore(res?.data?.is_has_more ? true : false);
            setTotalCount(res.data?.count || 0)
        } 
        setIsPreloader(false); 
    }
  
    return (
        <div className="admin-all-plans-wrap">
            <PageMeta {...pageMeta['AdminAllPlansView']} />
            {
                isPreloader && <PreloaderCustom />
            }
            {
                isModalDelete && 
                    <ModalWindow 
                        title={''} 
                        handleClick={handleIsDeletePlan} 
                    >
                    <div className='admin-trainings__dialogform-delete-wrap'>
                        <img 
                            className='admin-trainings__dialogform-delete-img' 
                            src={helpIcon} 
                            alt='img'
                        />
                        <div className='admin-trainings__dialogform-delete-text'>
                            {
                                !!deletePlan.name?.length ?
                                    <>
                                        <div>{translations['deleteExercise'][language]}</div>
                                        <div className='admin-trainings__dialogform-delete-text-name'>{deletePlan.name}</div>
                                    </>
                                    :
                                    <div>{translations['deleteRestTime'][language]}</div>
                            }
                        </div>
                    </div>
                </ModalWindow>
            }
            {
                isModalCopy && 
                    <ModalWindow 
                        title={translations['modalNewTrening'][language]} 
                        handleClick={handleIsCopyPlan} 
                    >
                        <div className='admin-all-plans__modal-input-wrap'>
                            <TextInput 
                                setValue={setNewTreningName} 
                                value={newTreningName} 
                                label={translations['enterNewName'][language]}
                            />
                        </div>
                    </ModalWindow>
            }
            <div className="admin-all-plans">
                <div className='admin-all-plans__main-wrap'>
                    <div className='admin-all-plans__btn--wrap'>
                        <div className='admin-all-plans__count-wrap'>
                            <div className='admin-all-plans__count'>{totalCount}</div>
                            <div className='admin-all-plans__count-text'>{translations['activeTrainings'][language]}</div>
                        </div>
                        <div className='admin-all-plans__btn-wrap'>
                            <DoubleButtons 
                                isActive={location.pathname === `/auth/${coachId}/trainings`}
                                leftBtnText={translations['trainings'][language]} 
                                rightBtnText={translations['exercises'][language]} 
                                onClickLeftBtn={() => navigate(`/auth/${coachId}/trainings`)} 
                                onClickRightBtn={() => navigate(`/auth/${coachId}/plans`)}
                            />
                        </div>
                        <div className='admin-all-plans__price-wrap'>
                            <div className='admin-all-plans__price'>${inCome}</div>
                            <div className='admin-all-plans__price-text'>{translations['yourIncome'][language]}</div>
                        </div>
                    </div>

                    <div className='admin-all-plans__users--wrap'>
                        {
                            !!exercises?.length ?
                                <div className='admin-all-plans__users-wrap' ref={scrollParentRef}>
                                    <InfiniteScroll
                                        pageStart={0}
                                        useWindow = { false } 
                                        getScrollParent={() => scrollParentRef.current}
                                        loadMore={handleLoadMore}
                                        hasMore={isHasMore}
                                        className='admin-all-plans__users'
                                    >
                                        <div className='admin-all-plans__users-header-wrap'>
                                            <div className='admin-all-plans__users-header-name'>
                                                <span className='admin-all-plans__users-header-name-span'>{translations['name'][language]}</span>
                                            </div>
                                            <div className='admin-all-plans__users-header-days'>{translations['duration'][language]}</div>
                                            <div className='admin-all-plans__users-header-qr-wrap'>
                                                <div className='admin-all-plans__users-header-qr'>{translations['copy'][language]}</div>
                                                <div className='admin-all-plans__users-header-edit'>{translations['edit'][language]}</div>
                                                <div className='admin-all-plans__users-header-delete'>{translations['delete'][language]}</div>
                                            </div>
                                        </div>
                                        {
                                            exercises.map((el, i) => (
                                                <div className="admin-all-plans__card" key={el._id}>
                                                    {
                                                        windowInnerWidth < 640 ?
                                                            <>
                                                                <div className='admin-all-plans__mobile-card-title--wrap'>
                                                                    <div className='admin-all-plans__mobile-card-title-wrap'>
                                                                        <NavLink 
                                                                            className='admin-all-plans__mobile-card-title' 
                                                                            to={`${el._id}`}
                                                                        >
                                                                            {el.name?.length ? el.name : 'Rest time'}
                                                                        </NavLink>
                                                                        <div className='admin-all-plans__mobile-card-days'>{el.time} {translations['minutes'][language]}</div>
                                                                    </div>
                                                                    <button 
                                                                        className={`admin-all-plans__card-btn ${!el?.name?.length ? 'disabledContainer' : ''}`} 
                                                                        onClick={() => handleCopt(el)}
                                                                    >
                                                                        <img 
                                                                            className="admin-all-plans__card-btn-img" 
                                                                            src={copyImgViolet} 
                                                                            alt="img"
                                                                        />
                                                                    </button> 
                                                                </div>
                                                                <div className='admin-all-plans__mobile-card-btn-wrap'>
                                                                    <button 
                                                                        className='admin-all-plans__mobile-card-btn admin-all-plans__mobile-card-btn--delete' 
                                                                        onClick={() => handleDelete(el)}
                                                                    >
                                                                        {translations['delete'][language]}
                                                                    </button>
                                                                    <button 
                                                                        className='admin-all-plans__mobile-card-btn' 
                                                                        onClick={() => handleEdite(el)}
                                                                    >
                                                                        {translations['edit'][language]}
                                                                    </button>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='admin-all-plans__card-title-wrap'>
                                                                    <div className='admin-all-plans__card-num'>{i + 1}</div>
                                                                    <img 
                                                                        className={`admin-all-plans__card-img ${!el?.preview?.length ? 'admin-all-plans__card-img--no-photos' : ''}`} 
                                                                        src={el?.preview?.length ? el?.preview : !el.name?.length ? restTimeImg : noPhotos} 
                                                                        alt="img"
                                                                    />
                                                                    <NavLink 
                                                                        className='admin-all-plans__title' 
                                                                        to={`${el._id}`}
                                                                    >
                                                                        {el.name?.length ? el.name : 'Rest time'}
                                                                    </NavLink>
                                                                </div>
                                                                <div className='admin-all-plans__card-days'>{formatTime(el.time, language)}</div>
                                                                <div className='admin-all-plans__card-btn-wrap'>
                                                                    <button 
                                                                        className={`admin-all-plans__card-btn ${!el?.name?.length ? 'disabledContainer' : ''}`} 
                                                                        onClick={() => handleCopt(el)}
                                                                    >
                                                                        <img 
                                                                            className="admin-all-plans__card-btn-img" 
                                                                            src={copyImgViolet} 
                                                                            alt="img"
                                                                        />
                                                                    </button> 
                                                                    <button 
                                                                        className='admin-all-plans__card-btn' 
                                                                        onClick={() => handleEdite(el)}
                                                                    >
                                                                        <img 
                                                                            className="admin-all-plans__card-btn-img" 
                                                                            src={editViolet} 
                                                                            alt="img"
                                                                        />
                                                                    </button> 
                                                                    <button 
                                                                        className='admin-all-plans__card-btn' 
                                                                        onClick={() => handleDelete(el)}
                                                                    >
                                                                        <img 
                                                                            className="admin-all-plans__card-btn-img" 
                                                                            src={deleteImgViolet} 
                                                                            alt="img"
                                                                        />
                                                                    </button> 
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </InfiniteScroll>
                                </div>
                                :
                                <>
                                    {
                                        !!searchValueSearchParams?.length ?
                                            <div className='admin-all-plans__empty-wrap'>{translations['noSearchResult'][language]}</div>
                                            :
                                            <div className='admin-users__empty-img-wrap'>
                                                <img
                                                    className='admin-trainings__empty-img'
                                                    src={notTreningImg}
                                                    alt='img' 
                                                />
                                                <div className='admin-all-plans__empty-wrap'>{translations['exercisesListEmpty'][language]}</div>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>

                <div className='admin-all-plans__add-btn-wrap'>
                    <NavLink 
                        className='mainBtnDark' 
                        to={`/auth/${coachId}/plans/create`}
                    >
                        + {translations['addExercise'][language]}
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default AdminAllPlansView;