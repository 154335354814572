import { uk, enUS } from 'date-fns/locale';

export let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],       
    ['blockquote'],
    
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],       
    
    [{ 'size': ['small', false, 'large', 'huge'] }],  
    
    [{ 'color': [] }, { 'background': [] }],         
    [{ 'align': [] }],
];

export let NEW = 'new';
export let NEWCARD = 'addNewCard';
export let PENDING = 'pending';
export let SUCCESS = 'success';
export let ERROR = 'error';
export let LAST = 'last';

export const dateFnsLocale = {
    'en': enUS,
    'ua':  uk,
}

export const countryPhoneLengths = {
    'cn': 11, // Китай
    'in': 10, // Індія
    'us': 11, // США
    'id': 10, // Індонезія
    'pk': 10, // Пакистан
    'br': 11, // Бразилія
    'ng': 13, // Нігерія
    'bd': 10, // Бангладеш
    'mx': 10, // Мексика
    'jp': 10, // Японія
    'et': 10, // Ефіопія
    'ph': 10, // Філіппіни
    'eg': 10, // Єгипет
    'vn': 11, // В'єтнам
    'cd': 9,  // Демократична Республіка Конго
    'tr': 11, // Туреччина
    'ir': 10, // Іран
    'th': 9,  // Таїланд
    'fr': 9,  // Франція
    'gb': 10, // Велика Британія
    'it': 10, // Італія
    'tz': 9,  // Танзанія
    'za': 9,  // ПАР
    'mm': 9,  // М'янма
    'ke': 10, // Кенія
    'kr': 11, // Південна Корея
    'co': 10, // Колумбія
    'es': 9,  // Іспанія
    'ug': 9,  // Уганда
    'ua': 12, // Україна
    'de': 9,  // Німеччина
    'at': 9,  // Австрія
    'ch': 9,  // Чеська
    'pl': 9,  // Польща
    'ro': 9,  // Румунія
};